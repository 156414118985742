import { render, staticRenderFns } from "./Pov.vue?vue&type=template&id=49e9d859&scoped=true&"
import script from "./Pov.vue?vue&type=script&lang=js&"
export * from "./Pov.vue?vue&type=script&lang=js&"
import style0 from "./Pov.vue?vue&type=style&index=0&id=49e9d859&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e9d859",
  null
  
)

export default component.exports