<template>
  <div>
    <!--    <iframe src="//player.bilibili.com/player.html?aid=356181358&bvid=BV1xX4y117nf&cid=1130830388&page=1&autoplay=0"-->
    <!--            scrolling="no"-->
    <!--            border="0" frameborder="no" framespacing="0" allowfullscreen="true" @click="test"></iframe>-->


    <!--    <div class="iframe-item">-->
    <!--      <div class="click-item"-->
    <!--           @click="openLink('https://www.bilibili.com/video/BV14s4y1o7iW/?spm_id_from=333.337.search-card.all.click&vd_source=32cecff6f9982be3417a532c60c3d294')">-->

    <!--      </div>-->
    <!--      <iframe src="//player.bilibili.com/player.html?aid=992835070&bvid=BV14s4y1o7iW&cid=1035190847&page=1&autoplay=0"-->
    <!--              scrolling="no" border="0" frameborder="no" framespacing="0" allowfullscreen="true"></iframe>-->
    <!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "Pov",
  methods: {
    test() {
      console.log('test')
    },
    openLink(link) {
      window.open(link)
    },
  }
}
</script>

<style scoped>
.iframe-item {
  position: relative;
}

.click-item {
  position: absolute;
  z-index: 2;
  width: 400px;
  height: 400px;
  cursor: pointer;
}
</style>